<template>
  <span v-bind="$attrs">{{ label }}</span>
</template>

<script setup lang="ts">
import type { DeliveryPromiseDays, DeliveryPromiseSku } from '~layers/app/composables/useDeliveryPromise'

export type Props = {
  item: DeliveryPromiseSku | null
  country?: string | null
}

const { t, d } = useI18n()

const props = defineProps<Props>()

const dayDiff = (date: Date): number => {
  const dateToCheck = new Date(date.getTime())
  dateToCheck.setHours(0)
  dateToCheck.setMinutes(0)
  dateToCheck.setSeconds(0)
  const dateNow = new Date()
  dateNow.setHours(0)
  dateNow.setMinutes(0)
  dateNow.setSeconds(0)
  const diffInMs = dateToCheck.getTime() - dateNow.getTime()

  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24))
}

const minPromise = computed(() => {
  if (!props.item?.result) {
    const tmp = new Date()
    let offset = 1 // NL & BE & DE

    // if (props.country !== 'DE') {
    //   offset = 2
    // }

    // 6 is Saturday (+1 day)
    if (tmp.getDay() === 6) {
      offset += 1
    }

    tmp.setHours(+(24 * offset))
    return tmp
  }

  return new Date(props.item.result.minPromise)
})

const maxPromise = computed(() => {
  if (!props.item?.result) {
    const tmp = new Date()
    let offset = 1 // NL & BE

    if (props.country === 'DE') {
      offset = 3
    }

    // 6 is Saturday (+1 day)
    if (tmp.getDay() === 6) {
      offset += 1
    }

    tmp.setHours(+(24 * offset))
    return tmp
  }

  return new Date(props.item.result.maxPromise)
})

const cutOffDateObject = computed<Date>(() => {
  if (!props.item?.result) {
    const tmp = new Date()
    // Set to today 23:00
    tmp.setHours(23)
    return tmp
  }

  return new Date(props.item.cutoffDateTime)
})

const cutOffDays = computed(() => dayDiff(cutOffDateObject.value))

const cutOffTime = computed<string>(() => {
  if (!props.item?.result) {
    return '23:00' // return readable time
  }

  return cutOffDateObject.value.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
})

const days = computed<DeliveryPromiseDays | null>(() => {
  return {
    minPromise: dayDiff(minPromise.value) + cutOffDays.value,
    maxPromise: dayDiff(maxPromise.value) + cutOffDays.value,
  }
})

const label = computed<string>(() => {
  if (days.value?.minPromise === 1 && days.value?.maxPromise === 1) {
    return t('Order before {cutOffTime}, delivered tomorrow', { cutOffTime: cutOffTime.value })
  }
  if (days.value?.minPromise === days.value?.maxPromise) {
    return t('{minPromise} days delivery time', { minPromise: d(minPromise.value, { weekday: 'long' }) })
  }
  if (props.country === 'BE' && days.value?.minPromise === 1 && days.value?.maxPromise === 2) {
    return t('Order before {cutOffTime}, sent today', { cutOffTime: cutOffTime.value })
  }
  if (days.value?.maxPromise > 2 && maxPromise.value.getDay() === 2) {
    return t('Ordered today, delivered {maxPromise}', { maxPromise: d(maxPromise.value, { weekday: 'long' }) })
  }

  return t('{minPromise} to {maxPromise} days delivery time', days.value)
})
</script>
